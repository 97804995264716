<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增记录</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>物品名称</b></label><br />
            <el-autocomplete v-model="model.NAME" :fetch-suggestions="query" placeholder="请输入物品名称" style="width: 500px" />
          </div>
          <div class="form-group">
            <label><b>数量</b></label>
            <div>
              <el-input-number v-model="model.CNT" :step="1"></el-input-number>
            </div>
          </div>
          <div class="form-group">
            <label><b>发生日期</b></label><br/>
            <el-date-picker v-model="model.EVENT_DT" type="date" placeholder="选择日期"/>
          </div>
          <div class="form-group">
            <label><b>经手人</b></label><br />
            <el-select v-model="model.HANDLE_NAME">
              <el-option
                  v-for="item in ls_teacher"
                  :key="item.ID"
                  :label="item.NAME"
                  :value="item.NAME">
              </el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <el-input type="text" v-model="model.DESCRIPTION" />
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {},
      ls_name:[],
      ls_teacher:[]
    }
  },
  methods:{
    init() {
      let self = this;
      this.sta={show:true,loading:false}
      this.model = {CNT: 1,EVENT_DT: new Date()};
      if (this.ls_name.length==0){
        this.whale.remote.getResult({
          url: "/api/School/UTL/RentApi/GetNames",
          completed: function (its) {
            self.ls_name = its.DATA.map((o)=>{return {value:o}})
          }
        })
      }
      if (this.ls_teacher.length == 0) {
        this.whale.remote.getCollection({
          url: "/api/School/ORG/TeacherApi/GetList",
          data: {},
          completed: function (its) {
            self.ls_teacher = its;
          }
        })
      }
    },
    submit(){
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/UTL/RentApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    },
    query(str,cb){
      let ret=str?this.ls_name.filter((o)=>{return o.value.toLowerCase().indexOf(str.toLowerCase())>=0}):this.ls_name;
      cb(ret)
    }
  }
}
</script>
